import type { ReadCashBasedInvoice } from "@silexpert/core";
import { EstimateInvoiceType, RoleAllowedToLogInAsClient } from "@silexpert/core";

export function useSalesComposable(item: any) {
  const dayjs = useDayjs();

  const isLocked = (): boolean =>
    isDefined(item.exported) ||
    (isDefined(item.dateVerified) &&
      !useRolesComposable().hasOneOfTheseRoles(RoleAllowedToLogInAsClient));

  const isCashBasedFactures = () => {
    return (
      isDefined(item) &&
      (isDefined(item.idAccountingFileType) || isDefined(item.idTypeAccountingFile)) &&
      !item.isDraft
    );
  };
  const isAccrualsFactures = () => {
    return isDefined(item) && isDefined(item.entries) && !item.isDraft;
  };

  const isFactures = () => {
    return isCashBasedFactures() || isAccrualsFactures();
  };

  const isDevisOrBrouillons = () => {
    return (
      (isDefined(item.idEstimateInvoiceType) &&
        [EstimateInvoiceType.DEVIS, EstimateInvoiceType.BON].includes(
          item.idEstimateInvoiceType,
        )) ||
      item.isDraft
    );
  };

  const isImportedDocument = () => {
    return isFactures() && !isDefined(item.estimatesInvoice);
  };

  const isCashBasedFacturesItemAttachedToTransaction = () => {
    return isDefined((item as ReadCashBasedInvoice)?.idAccountingTransaction);
  };

  const isInvoice = () => {
    return (
      isFactures() &&
      isDefined(item.estimatesInvoice) &&
      item.estimatesInvoice?.idEstimateInvoiceType === EstimateInvoiceType.FACTURE
    );
  };

  const isRemainsToBePaid = () => {
    return (
      isFactures() &&
      isDefined(item.estimatesInvoice) &&
      item.estimatesInvoice?.idEstimateInvoiceType === EstimateInvoiceType.RESTE
    );
  };

  const isAsset = () => {
    return (
      isFactures() &&
      isDefined(item.estimatesInvoice) &&
      item.estimatesInvoice?.idEstimateInvoiceType === EstimateInvoiceType.AVOIR
    );
  };

  const isDeposit = () => {
    return (
      isFactures() &&
      isDefined(item.estimatesInvoice) &&
      item.estimatesInvoice?.idEstimateInvoiceType === EstimateInvoiceType.ACOMPTE
    );
  };

  const isEstimate = () => {
    return isDevisOrBrouillons() && item.idEstimateInvoiceType === EstimateInvoiceType.DEVIS;
  };

  const isOrderForm = () => {
    return isDevisOrBrouillons() && item.idEstimateInvoiceType === EstimateInvoiceType.BON;
  };

  const isDraft = () => {
    return isDevisOrBrouillons() && item.isDraft;
  };

  const type = () => {
    if (isFactures()) {
      if (isAsset()) {
        return EstimateInvoiceType.AVOIR;
      }
      return EstimateInvoiceType.FACTURE;
    } else if (isEstimate()) {
      return EstimateInvoiceType.DEVIS;
    } else if (isOrderForm()) {
      return EstimateInvoiceType.BON;
    } else if (isDeposit()) {
      return EstimateInvoiceType.ACOMPTE;
    }
    return EstimateInvoiceType.FACTURE;
  };

  const date = (): number => {
    if (isCashBasedFactures()) {
      return dayjs(item.emissionDate).toDate().getTime();
    } else if (isAccrualsFactures()) {
      return dayjs(item.dateValue).toDate().getTime();
    } else if (isDevisOrBrouillons()) {
      return dayjs(item.emissionDate).toDate().getTime();
    }
    console.error(`getSalesItemDate: ${JSON.stringify(item)} does not seem to be a SalesItem`);
    return 0;
  };

  // equivalent to totalTaxIncluded, but considering generalDiscount
  const totalNetCash = () => {
    if (isFactures()) {
      const generalDiscount = (item?.estimatesInvoice?.generalDiscount2 ?? 0) / 100;
      const totalTtc = item?.estimatesInvoice?.totalTaxIncluded
        ? item?.estimatesInvoice?.totalTaxIncluded / 100
        : 0;

      if (isRemainsToBePaid()) {
        return totalTtc - generalDiscount;
      }

      const totalNetCash = isAsset() ? totalTtc + generalDiscount : totalTtc - generalDiscount;
      return roundAtXDecimals(totalNetCash);
    }
    if (isDevisOrBrouillons()) {
      const generalDiscount = (item?.generalDiscount2 ?? 0) / 100;
      const totalTtc = item?.totalTaxIncluded / 100;
      const totalNetCash = totalTtc - generalDiscount;
      return roundAtXDecimals(totalNetCash);
    }

    return -1;
  };

  const label = () => {
    if (isFactures()) {
      return item.estimatesInvoice?.label ?? "";
    }
    if (isDevisOrBrouillons()) {
      return item.label ?? "";
    }
    return "";
  };

  const frenchName = () => {
    if (isFactures()) {
      if (isImportedDocument() || isInvoice()) {
        return "facture";
      } else if (isRemainsToBePaid()) {
        return "reste à payer";
      } else if (isAsset()) {
        return "avoir";
      } else if (isDeposit()) {
        return "acompte";
      }
    } else if (isDevisOrBrouillons()) {
      if (isEstimate()) {
        return "devis";
      } else if (isOrderForm()) {
        return "bon de commande";
      } else if (isDraft()) {
        return "brouillon";
      }
    }
    return "document";
  };

  const frenchNameWithDefiniteArticle = () => {
    if (isFactures()) {
      if (isImportedDocument() || isInvoice()) {
        return "la facture";
      } else if (isRemainsToBePaid()) {
        return "le reste à payer";
      } else if (isAsset()) {
        return "l'avoir";
      } else if (isDeposit()) {
        return "l'acompte";
      }
    } else if (isDevisOrBrouillons()) {
      if (isEstimate()) {
        return "le devis";
      } else if (isOrderForm()) {
        return "le bon de commande";
      } else if (isDraft()) {
        return "le brouillon";
      }
    }
    return "le document";
  };

  const thirdPartyEmail = () => {
    if (isFactures()) {
      return item?.estimatesInvoice?.thirdParty?.email ?? "";
    } else if (isDevisOrBrouillons()) {
      return item?.thirdParty?.email ?? "";
    }
    return "";
  };

  return {
    date,
    type,
    totalNetCash,
    label,
    frenchName,
    frenchNameWithDefiniteArticle,
    thirdPartyEmail,
    isCashBasedFactures,
    isAccrualsFactures,
    isFactures,
    isDevisOrBrouillons,
    isImportedDocument,
    isRemainsToBePaid,
    isAsset,
    isDeposit,
    isEstimate,
    isOrderForm,
    isCashBasedFacturesItemAttachedToTransaction,
    isInvoice,
    isDraft,
    isLocked,
  };
}
